/* eslint-disable  @typescript-eslint/no-explicit-any */

export interface FormDefinition {
    valid: boolean;
    fields: {
        [key: string]: any;
    };
    rules: {
        [key: string]: any;
    };
}

/**
 * Checks for form input, can´t be empty
 */
export function formInputRequired(message = 'Required'): (value: any) => boolean|string {
    return (value) => value.trim() != '' || message
}

/**
 * Check if a value is a valid URL
 */
export function formIsValidURL(message = 'No valid URL'): (value: any) => boolean|string {
    return (value) => !value || /^(http|https):\/\/[^ "]+$/.test(value) || message
}

/**
 * Check if a value is a valid email
 */
export function formIsValidEmail(message = 'No valid e-mail address'): (value: any) => boolean|string {
    return (value) => {
        return value == '' || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value
            .toLowerCase()) || message
    }
}