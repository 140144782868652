export function click2DateURLCheck(url: string | null) : string | null {
    if (url && url.substring(0, 26) == "https://www.click2date.eu/") {
      const urlParts = url.split('/');
      if (urlParts[urlParts.length - 1] == '') urlParts.pop();

      if (urlParts.length == 4) {
        url = [...urlParts, 'appointment/start'].join('/');
      }
    }

    return url;
}