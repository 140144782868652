
import { BrandInfos } from "@/companyDashboardAPI/BrandInfos";
import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import DialogDivider from "./DialogDivider.vue";
import ModuleCard from "./ModuleCard.vue";

@Component({
    components: {
        DialogDivider: DialogDivider,
        ModuleCard: ModuleCard,
    }
})
export default class ColorPreview extends Vue {
    @Prop({required: true})
    public readonly title!: string;

    @Prop({required: true})
    public readonly brandInfos!: BrandInfos;

    @Prop({required: true})
    public readonly logo!: string;

    public iconSize = 50;
    public modulCardHeight = 50;
}
